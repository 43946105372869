// from https://stackoverflow.com/questions/72099348/how-to-add-global-interfaces-to-nuxt-project
import {StringUnion} from "~/helpers/StringUnion";
import type {SlideType} from "~/components/slideshow/Slideshow.vue";

export type ErrorCodeOption = 'unexpected'|'unauthorized'|'notfound'|'unavailable'|'ratelimit';
export type ResponseStatus = 'success'|ErrorCodeOption;

export type SimpleNoContentResponse = {
    status: ResponseStatus
}
export type ContentResponse<T> = (
    {
        status: 'success'
        data: T
    }
    |
    {
        status: ErrorCodeOption,
    }
    )

export type PageContent = (
    {
        title: string
        body?: string
        button?: string
        image?: string
    }
)

export type ContentModule = ({
        label: string
        type: string
        start: PageContent
        end: PageContent
        intro?: SlideType[]
        slides?: SlideType[]
        cardGame?: { rounds: CardGameRound[]}
        observe?: { text: string, subjects: ObserveSubject[]}
    }
)

export type ObserveSubject = ({
        label: string,
        executor: string,
        hasVictim: boolean,
        victim?: string,
        intro: SlideType[],
        practice: {
            checks:
                ObserveCheck[]
        }
    }
)

export type ObserveCheck = ({
    id: string,
    value: string
})

export type CardGameRound = ({
    start: PageContent
    questions: CardGameQuestion[]
    faster: CardGameSubRound
    normal: CardGameSubRound
    onscreen: CardGameSubRound
})

export type CardGameSubRound = ({
    speed?: number
    text?: string
})

export type CardGameQuestion = ({
    question: string
    answers: string[]
    distractors: string[]
})

export type ModalType = 'menu' | 'teams' | 'regroup' | 'finish' | 'program' | 'members' | 'returnToMeetingPoint' | 'timeIsUp';

export type TimerState = 'none' | 'started' | 'done';
export type SlotState = 'start' | 'started' | 'done';

export type ObserveState = 'start' | 'observer' | 'executor' | 'victim' | 'judge' | 'rounds' | 'end' | 'done'
export type CardGameState = 'start' | 'countdown' | 'question' | 'answer' | 'bumper' | 'finished'
export type CardGameRoundSubType = 'normal' | 'faster' | 'onscreen'

export type ActivityStateContent = 'start' | 'intro' | 'slides' | 'modules' | 'cardGame' | 'observe' | 'end';
export type ActivityState = ActivityStateContent | 'preStart' | 'standby' | 'location' | 'wait' | 'observe_subject' | 'break' | 'eval' | 'none';
export interface ActivityStateObject {
    state: ActivityState;
    index: number;
}

export type ActivityType = 'intro' | 'leh_praktijk' | 'mix_bzv' | 'brand_praktijk' | 'mix_oc' | 'endgame' | 'outro';
export type SlotType = 'slot'|'break';

export interface ProgressIconData {
    icon: string
    isChecked: boolean
}
export interface SlotProgressIcon {
    icons: string[]
    isChecked: boolean
}

export const ImageButton = StringUnion(
    "instructor",
    "program",
    "flag_A",
    "flag_B",
    "flag_C",
    "flag_D",
);

// todo: why was the next line commented out?
export type ImageButton = typeof ImageButton.type;
//
// export type ImageButton = 'instructor'|'program'|'shirtA'|'shirtB'|'shirtC'|'shirtD'

export interface Member {
        id?: number
        avatar: string
        teamId?: number | null
    }

export interface Members {[k: string]: Member}
export interface Teams {[k: string]: Team}

export interface Team {
        id: number
        name: string
        secret: string
        members?: Members
        activeSlotComplete?: boolean
        linked?: boolean,
        returnToMeetingPoint?: boolean
        setupComplete?: boolean
        lastSeen?: Date
    }

export interface LocationTeam extends Team {
    location?: string
}

export interface CourseConfig {
    schedule: {
        slots: Slot[],
        teams: { activities: ActivityType[] }[]
    },
    // server can provide content using keys of type ActivityType, should support any data
    content: { [k: string]: any }
}

export interface Slot {
    id: string
    type: SlotType
    label: string
    start?: {
        h: number
        m: number }
    duration: number }

export interface Session {
    id: number
    active: boolean
    secret: string
    teams: Teams
    program: string
    config: CourseConfig | Record<string, never>
    activeSlotIdx: number
    activeSlotState: SlotState
    activityState: ActivityStateObject
}

// export interface TeamSession {
//     active: boolean
//     config: CourseConfig | Record<string, never>
//     program: string
//     teams: Teams
//     activeSlotIdx: number
//     activeSlotState: SlotState
// }



